import React, { useState, useContext } from "react"
import Seo from "../components/seo"
import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import cellopic from "../images/brand/cello_1000x2100.jpeg"

const Register = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    organization: "",
    error: null,
  })

  const { setUser } = useContext(AuthContext)

  const termsAndConditions = "https://siyomusic.org/terms-and-conditions/"

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setData({ ...data, error: null })

    try {
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)

      await result.user.sendEmailVerification()

      await result.user.updateProfile({
        displayName: data.firstName + " " + data.lastName,
      })

      await firebase
        .firestore()
        .collection("users")
        .doc(result.user.uid)
        .set({
          name: data.firstName + " " + data.lastName,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          organization: data.organization,
          uid: result.user.uid,
          syfirRole: "publicUser",
        })

      setUser(result)
      navigate("/redirect-register")
    } catch (err) {
      setData({ ...data, error: err.message })
    }
  }

  return (
    // *************************Original******************************************
    <main>
      <div
        className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          className="object-cover object-center w-full h-full"
          src={cellopic}
          alt=""
          width="760"
          height="1024"
        />
      </div>
      <Seo title="Login" />
      <div className="relative sm:flex">
        <div className="sm:w-1/2 bg-white">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                <Link to="/">
                  <div className="">
                    <StaticImage
                      src="../images/siyo-logo.png"
                      width={80}
                      quality={100}
                      alt="Si-Yo Music Logo"
                    />
                    <span> </span>
                    <StaticImage
                      src="../images/ficec-logo-dark.png"
                      width={90}
                      quality={100}
                      alt="FICEC Logo"
                    />
                  </div>
                </Link>
                <br />
                Create Account
              </h1>
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="firstName"
                      className="form-input w-full border p-1 rounded"
                      type="text"
                      required
                      name="firstName"
                      value={data.firstName}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="lastName"
                      className="form-input w-full border p-1 rounded"
                      type="text"
                      required
                      name="lastName"
                      value={data.lastName}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="organization"
                    >
                      Organization
                    </label>
                    <input
                      className="form-input w-full border p-1 rounded"
                      type="text"
                      name="organization"
                      value={data.organization}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="form-input w-full border p-1 rounded"
                      type="text"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="form-input w-full border p-1 rounded"
                        autoComplete="on"
                        type="password"
                        name="password"
                        value={data.password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    {/* <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-sm ml-2">
                        Email me about product news.
                      </span>
                    </label> */}
                  </div>

                  <div className="text-sm text-gray-700 my-3">
                    By creating an account, you agree to the{" "}
                    <a
                      className="text-indigo-500 hover:text-indigo-600"
                      target="_blank"
                      rel="noreferrer"
                      href={termsAndConditions}
                    >
                      terms & conditions
                    </a>
                    .
                  </div>

                  <input
                    type="submit"
                    value="Register"
                    className=" py-1 px-4 rounded bg-indigo-500 border hover:bg-indigo-600 text-white ml-3"
                  />
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    to="/login"
                  >
                    Log In
                  </Link>
                </div>

                {/* warning message */}
                {data.error ? (
                  <div className="mt-5">
                    <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                      <svg
                        className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                        viewBox="0 0 12 12"
                      >
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <span className="text-sm">{data.error}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Register
